import React from 'react';
import img2 from '../images/livingroom.jpg';
import img4 from '../images/grow_chart.svg';
import img5 from '../images/community.svg';
import img6 from '../images/commercial.jpg'

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are deeply committed to the growth and success of our clients.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="md:px-[100px] grid sm:grid-cols-2 lg:grid-cols-2 gap-10">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="commercial properties in dubai" className="rounded-xl group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img6} />
                                    <h2 className="font-semibold my-4 text-2xl text-center group-hover:scale-[1.15]">Al Ghaith Business Space</h2>
                                    <p className="text-md font-medium " >
                                    At Al Ghaith Business Space, we specialize in providing high-quality, flexible business spaces that cater to your evolving professional needs. Whether you're a startup seeking an inspiring office, an established company in need of larger premises, or a dynamic business looking for a temporary workspace, we have a wide range of solutions to suit you.</p>
                                    <br/>
                                    <p className="text-md font-medium ">Our Spaces Our portfolio includes a variety of office spaces, retail units, and commercial properties, all strategically located in prime business hubs. Each space is designed to foster productivity, innovation, and growth, offering state-of-the-art facilities, modern interiors, and customizable layouts to meet your specific requirements.</p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-xl group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center group-hover:scale-[1.15]">Al Ghaith Living Space</h2>
                                    <p className="text-md font-medium">
                                    At Al Ghaith Living Space, we believe that a home is more than just a place to live—it’s a reflection of your lifestyle. Our thoughtfully designed living spaces cater to the modern individual or family, offering comfort, convenience, and a sense of community. Whether you're looking for a cozy apartment in the heart of the city, a serene suburban home, or a luxury high-rise with panoramic views, we have the perfect space for you.
                                    </p>
                                    <br/>
                                    <p className="text-md font-medium">
                                    Each of our properties is carefully selected and crafted with your needs in mind. From contemporary designs and top-of-the-line amenities to proximity to schools, shopping centers, and public transport, our living spaces offer everything you need for a seamless living experience. With flexible leasing options and personalized service, we make the journey to finding your new home effortless.                                    </p>

                                </div>
                            </div>

                            {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Maintenance</h2>
                                    <p className="text-md font-medium">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.

                                    </p>
                                </div>
                            </div> */}

                            {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">General IT Consultations</h2>
                                    <p className="text-md font-medium">
                                        Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.
                                    </p>
                                </div>
                            </div>                     */}
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <img src={img5} className='w-[72px] h-[72px]'>
                                </img>

                            </div>
                            <h3 className="text-3xl  text-blue-900 
                            font-bold">We <span className='font-black'>Develop <br/> communities beyond</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    Al Ghaith Properties extends its social impact to develop communities for beyond, as we built large mosques & an islamic cultural center in Dubai. as well as residential complexes and mosques in various African countries.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                            <img src={img4} className='w-[72px] h-[72px]'>
                            </img>                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold">Grow <span className='font-black'>With<br/> Al Ghaith Properties</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Al Ghaith Properties extends its social impact to develop communities for beyond, as we built large mosques & an islamic cultural center in Dubai. as well as residential complexes and mosques in various African countries.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;